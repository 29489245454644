@import '../../../../scss/theme-bootstrap';

$grid-row-gutter: 15px;

.grid-row-formatter {
  position: relative;
  width: 100%;
  &__wrapper {
    width: initial;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    text-align: $ldirection;
    margin-#{$ldirection}: auto;
    margin-#{$rdirection}: auto;
  }
  &__grid {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    @include breakpoint($landscape-up) {
      flex-direction: row;
    }
    .grid-row-formatter__wrapper--mobile-2-column & {
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      @include breakpoint($landscape-up) {
        justify-content: center;
        flex-wrap: nowrap;
      }
    }
    &-item {
      width: 100%;
      .grid-row-formatter__wrapper--mobile-2-column & {
        width: calc(50% - (#{$grid-row-gutter}/ 2));
        @include breakpoint($landscape-up) {
          width: 100%;
        }
      }
      &--zero-margin {
        .grid-row-formatter__wrapper--mobile-2-column & {
          width: 50%;
          @include breakpoint($landscape-up) {
            width: 100%;
          }
        }
      }
    }
    &-item:not(:last-of-type):not(.grid-row-formatter__grid-item--zero-margin) {
      margin-bottom: 20px;
      @include breakpoint($landscape-up) {
        margin-bottom: 0;
        margin-#{$rdirection}: $grid-row-gutter;
      }
    }
  }
  &__header {
    margin: 5px 0;
  }
  &__header-content {
    display: inline-block;
  }
  &--standard-margin {
    padding: 0 $grid-row-gutter;
  }
  .content-block__line p {
    line-height: inherit;
  }
}
